html {
	font-family: sans-serif;
	overflow: hidden;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#videodiv {
	animation: 1s sticker ease-in;
}

@keyframes sticker {
	0% {
		transform: rotateZ(10deg) scale(1.4);
		filter: blur(4px);
		drop-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.5);
		opacity: 0%;
	}

	10% {
		opacity: 100%;
	}

	100% {
		transform: rotateZ(0deg) scale(1);
		filter: blur(0px);
		drop-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0); /* Shadow fully faded out */
	}
}
